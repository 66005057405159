import React from 'react';
import Link from 'next/link';
import ActiveLink from "./utils/ActiveLink";
import Image from 'next/legacy/image';
import { Phone, Envelope } from '@styled-icons/fa-solid';
import { Facebook, Twitter, Instagram } from '@styled-icons/fa-brands';
import styles from '../styles/Footer.module.scss';

export default function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer>
      <div className="relative h-72 w-full">
        <Image
          src="https://bbweb-9c72.kxcdn.com/themes/custom/bespoke/images/footer-roses/footer-roses-xlarge.webp"
          alt="Rose garland"
          layout="fill"
          objectFit="cover"
          objectPosition="center"
        />
      </div>
      <div className={styles.footer}>
        <div className={`${styles.footerConnect} container mx-auto pt-2 grid grid-cols-1 lg:grid-cols-12`}>
          <div className={`${styles.footerConnectHeading} lg:col-start-3 lg:col-span-2 flex items-center`}>Need Help?</div>
          <div className="flex flex-col lg:col-span-3 pt-1">
            <div className="text-center lg:text-left"><Envelope size="20" /> <Link href="mailto:info@bespokeblossoms.com">info@bespokeblossoms.com</Link></div>
            <div className="text-center lg:text-left"><Phone size="20" /> <Link href="tel:250-382-0243">250-382-0243</Link></div>
          </div>
          <div className={`${styles.footerConnectHeading} lg:col-span-2 flex items-center`}>Share Your Blossoms</div>
          <div className="flex flex-row items-center -mt-2">
            <Link className="px-2" href="mailto:info@bespokeblossoms.com"><Envelope size="20" /></Link>
            <Link className="px-2" href="https://www.facebook.com/BespokeBlossom"><Facebook size="20" /></Link>
            <Link className="px-2" href="https://instagram.com/bespokeblossoms"><Instagram size="20" /></Link>
          </div>
        </div>
        <div className={styles.footerBottom}>
          <div className="container mx-auto grid grid-cols-1 md:grid-cols-6 lg:grid-cols-12">
            <div className="lg:col-span-5">
              <div className="pb-12">
                <Image alt="Bespoke Blossoms Logo" src="/images/bespoke-box-logo.png" width={335} height={135} />
              </div>
              <div className="pb-12">
                &copy;{year} Bespoke Blossoms All rights reserved.
              </div>
            </div>
            <div className="lg:col-span-3">
              <nav>
                <ul className={styles.footerMenu}>
                  <li><ActiveLink href="/">Home</ActiveLink></li>
                  <li><ActiveLink href="/weddings">Weddings</ActiveLink></li>
                  <li><ActiveLink href="/blog">Blog</ActiveLink></li>
                  <li><ActiveLink href="/business">Flowers For Business</ActiveLink></li>
                </ul>
              </nav>
            </div>
            <div className="lg:col-span-2 mb-10">
              <ul className={styles.footerMenu}>
                <li><ActiveLink href="/about">About</ActiveLink></li>
                <li><ActiveLink href="/policies">Policies</ActiveLink></li>
                <li><ActiveLink href="/contact">Contact</ActiveLink></li>
              </ul>
            </div>
            <div className="lg:col-span-2 text-center">
              <div className="text-xl">10am-5pm PST Mon - Sat</div>
              <div className={styles.footerPhone}><Link href="tel:2503820243">250-382-0243</Link></div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}